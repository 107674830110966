import { template as template_5d7f985ee87c4923aca24f6906c41804 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5d7f985ee87c4923aca24f6906c41804(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
