import { template as template_eff533a2e527416886fb4b67bbf60631 } from "@ember/template-compiler";
const SidebarSectionMessage = template_eff533a2e527416886fb4b67bbf60631(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
