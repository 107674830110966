import { template as template_c7e2121f90c64d7d88bd5e3613823793 } from "@ember/template-compiler";
const WelcomeHeader = template_c7e2121f90c64d7d88bd5e3613823793(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
