import { template as template_41b04649b00d4bb8b8e8594ae3917314 } from "@ember/template-compiler";
const FKText = template_41b04649b00d4bb8b8e8594ae3917314(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
