import { template as template_dd05add21ef846be9c62a791f1ef78ae } from "@ember/template-compiler";
const FKLabel = template_dd05add21ef846be9c62a791f1ef78ae(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
