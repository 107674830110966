import { template as template_32ce3f6d11b0480f81027d6c02a56dc3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_32ce3f6d11b0480f81027d6c02a56dc3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
